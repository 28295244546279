import React, { ReactNode, useEffect, useState } from 'react';
import Footer from '../Footer/Footer';
import NavBar from '../NavBar/NavBar';
import styled from 'styled-components';
import logo from '../../assets/img/sv-logo.png';
import { scrollToView } from '../utils';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from 'gatsby'
import { Menu as MenuIcon } from "@material-ui/icons";
import TempDialog from './TempDialog';
import { ClickAwayListener, Dialog, DialogContent } from '@material-ui/core';
import SvFooter from './svFooter';

interface Props {
  children: ReactNode;
  location: any;
}

export const query = graphql`
  {
    allStrapiSavanoriaiVaikams {
      edges {
        node {
          HeroTitle
          Rekvizitai {
            Antraste
            instagram
            linkedin
            facebook 
          }
          SavanoriaiHero {
            id
            colorProp
			      url
            nuotrauka {
              url
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
	strapiSavanoriaiVaikams {
		pagrindinis_tekstas
		pagrindine_nuotrauka {
        localFile {
          publicURL
        }
		  }
	  }
  }
`
const Layout = ({ children, location }: Props) => {

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const showModal = params.get("showModal");
    setShowModalQueryParam(showModal === 'true');
  }, []);

  let data = useStaticQuery(query)
  const savanoriai = data.allStrapiSavanoriaiVaikams.edges[0].node.SavanoriaiHero

  const socs = data?.allStrapiSavanoriaiVaikams?.edges[0]?.node?.Rekvizitai
  const isBrowser = typeof window !== 'undefined'
  const [shouldTempDialogBeVisible, setShouldTempDialogBeVisible] = useState(false);

  setTimeout(() => {

    setShouldTempDialogBeVisible(process.env.GATSBY_PUSLAPIS === 'savanoriaitevams');
  }, 5000)


  function isAfterRugpjutis31(date: Date) {
    const today = new Date("2022-08-31T23:59:59.817Z")
    return date > today;
  }

  const [showModalQueryParam, setShowModalQueryParam] = useState(false)
  const [open, setOpen] = useState(false)
  const [value, setValue] = useState('')

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [deviceSize, changeDeviceSize] = useState(isBrowser ? window?.innerWidth : '');

  useEffect(() => {
    const resizeW = () => changeDeviceSize(isBrowser ? window?.innerWidth : '');

    window?.addEventListener("resize", resizeW); // Update the width on resize

    return () => window?.removeEventListener("resize", resizeW);
  });

  const isSavanoriaiVaikams = () => {
    return process.env.GATSBY_PUSLAPIS === 'savanoriaivaikams';
  }

  const isOneOf2 = () => {
    return process.env.GATSBY_PUSLAPIS === 'savanoriaitevams' || process.env.GATSBY_PUSLAPIS === 'kriziucentrai';
  }

  return <>
    <>
      {isOneOf2() && <NavBar />}
      {isSavanoriaiVaikams() && deviceSize > 980 && <>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: '1160px', margin: '0 auto', width: '100%' }}>
          <div style={{ flex: '1', cursor: 'pointer' }} onClick={() => { setMobileMenuOpen(!mobileMenuOpen); navigate('/') }}><img style={{ width: '160px', padding: '16px 0' }} src={logo}></img></div>
          <div style={{ display: 'flex', flex: '1' }}>
            <div onClick={() => scrollToView("programos")} style={{ fontSize: '20px', color: '#0E558F', marginRight: '1.5rem', cursor: 'pointer' }}>Programos</div>
            <div onClick={() => { scrollToView("ataskaitos"); }} style={{ fontSize: '20px', color: '#0E558F', marginRight: '1.5rem', cursor: 'pointer' }}>Ataskaitos</div>
            <div onClick={() => scrollToView("kontaktai")} style={{ fontSize: '20px', color: '#0E558F', marginRight: '1.5rem', cursor: 'pointer' }}>Kontaktai</div>
            <div onClick={() => navigate('/12proc')} style={{ fontSize: '20px', color: '#0E558F', marginRight: '1.5rem', cursor: 'pointer', whiteSpace: 'nowrap' }}>1,2% GPM</div>
          </div>
          <div style={{ display: 'flex', flex: '1' }}>
            <Button style={{ marginRight: '10px' }} onClick={() => handleOpen()}>Savanoriauk</Button>
            <Button onClick={() => navigate('/paremk')}>Paremk</Button>
          </div>
        </div>
      </>}

      {isSavanoriaiVaikams() && deviceSize < 980 && <>
        <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: '16px' }}>
          <img style={{ width: '125px' }} src={logo} onClick={() => navigate('/savanoriai-vaikams')}></img>
          <MenuIcon style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setMobileMenuOpen(!mobileMenuOpen)}></MenuIcon>
        </div>
        {mobileMenuOpen &&
          <ClickAwayListener onClickAway={() => setMobileMenuOpen(false)}>
            <MobileNavas >
              <MobileItemas onClick={() => { setMobileMenuOpen(!mobileMenuOpen); scrollToView("programos") }}>Programos</MobileItemas>
              <MobileItemas onClick={() => { setMobileMenuOpen(!mobileMenuOpen); scrollToView("ataskaitos") }} >Ataskaitos</MobileItemas>
              <MobileItemas onClick={() => { setMobileMenuOpen(!mobileMenuOpen); scrollToView("kontaktai") }} >Kontaktai</MobileItemas>
              <MobileItemas onClick={() => { setMobileMenuOpen(!mobileMenuOpen); navigate('/12proc') }} >1,2% GPM</MobileItemas>

              <Button onClick={() => handleOpen()} style={{ alignSelf: 'center', marginBottom: '16px' }}>Savanoriauk</Button>
              <Button onClick={() => { setMobileMenuOpen(!mobileMenuOpen); navigate('/paremk') }} style={{ alignSelf: 'center', marginBottom: '16px' }}>Paremk</Button>
            </MobileNavas>
          </ClickAwayListener>}
      </>}

      <div style={{ display: 'flex', flexDirection: 'column' }}>{children}</div>
      {isOneOf2() && <Footer />}
      {isSavanoriaiVaikams() && <SvFooter socs={socs} />}

      <Dialog maxWidth={false} open={open} onClose={handleClose}>
        <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Headeris>Prisidėk prie mūsų!</Headeris>
          <CardWrapper>
									{savanoriai.map((item: any, index: number) => {
										return (
											<Image
												src={item.nuotrauka?.localFile?.publicURL}
												onClick={() => window.open(item.url)}
											/>
										)
									})
									}</CardWrapper>
        </DialogContent>
      </Dialog>

      {shouldTempDialogBeVisible && <TempDialog />}
    </>
  </>
};

export default Layout;

const Sec = styled.div`
  flex: 1 0 300px;
  min-width: 300px;
  display: flex;
  justify-content: center;


  @media (max-width: 1200px) {
    flex: 1 0 250px;
    min-width: 250px;
	}

  @media (max-width: 900px) {
    flex: 1 0 200px;
    min-width: 200px;
	}

  @media (max-width: 700px) {
    flex: 1 0 150px;
    min-width: 150px;
	}

  @media (max-width: 500px) {
    flex: 1 0 100px;
    min-width: 100px;
	}
`

const Button = styled.button`
	text-transform: uppercase;
	border: none;
	min-height: 52px;
	border-radius: 12px;
	color: #fff;
  background: #0E558F;
	font-size: 16px;
	font-weight: 600;
	
	text-align: center; 

  letter-spacing: 0.03em;
  color: #FFFFFF;
  padding: 0 1.5rem;
`

const Headeris = styled.div`
	font-size: 48px;
	line-height: 140%;

	/* Secondary Blue */
	color: #0E558F;
	text-align: center;
	padding-top: 1.5rem;

  @media (max-width: 1000px) {
		font-size: 28px;
	}
`

const MobileItemas = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 140%;
  /* identical to box height, or 28px */
  color: #0E558F;
  text-align: center;
  cursor: pointer;
  margin-bottom: 16px;

  &:nth-child(1) {
    margin-top: 16px;
  }
`

const MobileNavas = styled.div`
background: white;
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
position: absolute;
z-index: 1000;
top: 92px;
box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`

const CardWrapper = styled.div`
	display: flex;
	max-width: auto;
	max-height: auto;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 30px;
	flex-shrink: 1;
	/* justify-content: center; */

	@media (max-width: 900px) {
		flex-direction: column;
		align-items: center;
		max-width: 100%;
		height: auto;
		margin: 32px 16px;
	}
`

const Image = styled.img`
  display: flex;
  flex: 0 0;
  cursor: pointer;
  max-width: 300px;
  height: 100%;
  width: 300px;

  &:last-of-type {
	width: 150px;
  }
`