import React from 'react';
import Layout from './src/components/Layout/Layout';
import { ThemeProvider, createTheme, createStyles } from '@material-ui/core';
import './styles/styles.scss';
import { Helmet } from 'react-helmet';

let theme = createTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },

    overrides: {
        MuiMenuItem: createStyles({}),
        // MuiCollapse: {
        //     container: {
        //         padding: '0 1rem'
        //     },
        //     elavation: {
        //         boxShadow: 'none'
        //     }
        // },
        MuiAccordion: {
            root: {
                boxShadow: 'none !important',
                // '&::before': {
                //     backgroundColor: 'white',
                //     left: 'auto'
                // }
            },
            // '&::before': {
            //     backgroundColor: 'white',
            //     left: 'auto'
            // }
        },
        MuiInputBase: {
            input: {
                backgroundColor: "white"
            }
        },
        MuiFormControlLabel: {
            root: {
                marginRight: 46,
                // color: "black"
            }
        },
        MuiFormLabel: {
            root: {
                color: "rgba(0, 0, 0, 0.87)"
            }
        },
        MuiDialog: {
            paper: {
                padding: '0 !important'
            }
        }
    },
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: process.env.GATSBY_PUSLAPIS === 'savanoriaivaikams' ? '#0E558F' : '#87bf75',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#446B91',
            // main: '#f00',
            // dark: will be calculated from palette.secondary.main,
        },
    }
});

export const wrapPageElement = ({ element, props }) => {
    return <ThemeProvider theme={theme}>
        {/* savanoriaivaikams google tagas */}
        {process.env.GATSBY_PUSLAPIS === 'savanoriaivaikams' && <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-YGBXVQLF50"></script>
            <script>
                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-YGBXVQLF50');
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
          });
        `}
            </script>

        </Helmet>}

        {process.env.GATSBY_PUSLAPIS === 'savanoriaivaikams' && <>
        <Helmet>

            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TBHWHKT');`}</script>
<meta name="facebook-domain-verification" content="nzarvdg0ou3l40c43kgisxu4u70n16" /> 
            {/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TBHWHKT"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>{``}</noscript> */}
        </Helmet>
        </>}

        {/* savanoriaitevams google tagas */}
        {process.env.GATSBY_PUSLAPIS === 'savanoriaitevams' && <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-WJ2N1Z4DY0"></script>
            <script>
                {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-WJ2N1Z4DY0');
        `}
            </script>
        </Helmet>}

        {/* savanoriaitevams fb pixel */}
        {process.env.GATSBY_PUSLAPIS === 'savanoriaitevams' && <>
        <Helmet>
            <script>
                {`!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '1249582802520447');
  fbq('track', 'PageView');`}
            </script>
        </Helmet>
        </> }

        {process.env.GATSBY_PUSLAPIS === 'savanoriaitevams' && <>
        <Helmet>
            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-TVHHBQJ');`}</script>
<meta name="facebook-domain-verification" content="ubf38t8l5ru5cszhcndnaehmnr678r" /> 

            {/* <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TVHHBQJ"
                height="0" width="0" style="display:none;visibility:hidden"></iframe>{``}</noscript> */}
        </Helmet>
        <Helmet>

        </Helmet>
        </>}

        <Helmet>
            {process.env.GATSBY_PUSLAPIS === 'savanoriaivaikams' && <title>Savanoriai Vaikams</title>}
            {process.env.GATSBY_PUSLAPIS === 'savanoriaitevams' && <title>Savanoriai Tėvams</title>}
            {process.env.GATSBY_PUSLAPIS === 'niekienovaikaimusuvaikai' && <title>Niekieno vaikai - mūsų vaikai!</title>}
        </Helmet>


        {process.env.GATSBY_PUSLAPIS === 'niekienovaikaimusuvaikai' && <>
            <Helmet>
                <script async src="https://www.googletagmanager.com/gtag/js?id=G-XE4PQZFYG0"></script>
                <script>
                    {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
     gtag('consent', 'default', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'analytics_storage': 'denied'
        });
                gtag('config', 'G-XE4PQZFYG0');`}
                </script>
            </Helmet>
            <Helmet>
            <script>
{`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '629297752319353');
fbq('track', 'PageView');`}
</script>

            </Helmet>
            <Helmet>
                <meta name="facebook-domain-verification" content="3i9uf76yg01nikoohsnrusd8ng3bsz" />
            </Helmet>

            <Helmet>
            <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PSDN8VQ');`}</script>
            </Helmet>
        </>}


        <Layout {...props}>{element}</Layout>
    </ThemeProvider>
};






 

// Additionally, paste this code immediately after the opening <body> tag:

 

// <!-- Google Tag Manager (noscript) -->

// <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PSDN8VQ"

// height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>

// <!-- End Google Tag Manager (noscript) -->