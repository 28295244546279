import marked from 'marked';
import draugystesPagrindinis from '../assets/img/draugystes-pagrindinis.png'
import socCentraiPagrindinis from '../assets/img/soc-centrai-pagrindinis.png'

export function getMarkdownText(text: string) {
    let myRenderer = new marked.Renderer();
    myRenderer.link = function (href: string, title, text) {
        var external, newWindow, out;
        external = /^https?:\/\/.+$/.test(href);
        newWindow = external || title === 'newWindow';
        out = "<a href=\"" + href + "\"";
        if (newWindow) {
            out += ' target="_blank"';
        }
        if (title && title !== 'newWindow') {
            out += " title=\"" + title + "\"";
        }
        return out += ">" + text + "</a>";
    };

    marked.setOptions({renderer: myRenderer});

    var rawMarkup = marked(text, { sanitize: true });
    return { __html: rawMarkup };
}



export function getData(obj: any, property: string) {
    switch (process.env.GATSBY_PUSLAPIS) {
        case 'kriziucentrai':
            return obj.strapiSocCentrai[property];
        case 'niekienovaikai':
            break;
        case 'savanoriaitevams':
        case 'savanoriaivaikams':
            return obj.strapiDraugystes[property];
        default:
            console.error("current env:  ", process.env.GATSBY_PUSLAPIS, `${property} nerasta`);
    }
}

export function getMainImage() {
    switch (process.env.GATSBY_PUSLAPIS) {
        case 'kriziucentrai':
            return socCentraiPagrindinis;
        case 'niekienovaikai':
            break;
        case 'savanoriaitevams':
            return draugystesPagrindinis;
        case 'savanoriaivaikams':
        default:
            console.error("current env: ", process.env.GATSBY_PUSLAPIS, `img nerasta`);
    }
}


export function getBaseUrlForEnv() {
    switch (process.env.GATSBY_PUSLAPIS) {
        case 'kriziucentrai':
            return '/pirmas';
        case 'niekienovaikai':
            break;
        case 'savanoriaitevams':
            return '/antras';
        case 'savanoriaivaikams':
            return '/savanoriai-vaikams';
        default:
            console.error("current env: ", process.env.GATSBY_PUSLAPIS, `base url for env`);
    }
}

export function scrollToView(where: any) {
    if (!document?.getElementById(where)) {
        window.location.href = `/#${where}`;
        document?.getElementById(where)?.scrollIntoView();
    }
    document?.getElementById(where)?.scrollIntoView();
};

