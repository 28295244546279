import { Dialog } from "@material-ui/core";
import React, { useState } from 'react';
import styled from 'styled-components';
import newdrauge from '../../assets/img/draugepopup_bex.png';

const TempDialog = () => {

    const [modalOpen, setModalOpen] = useState(true)

    return <Dialog onClose={() => { setModalOpen(false) }} open={modalOpen}>
        <div style={{}}>
            <div style={{position: 'relative'}}>
                <img style={{ maxWidth: '100%' }} onClick={() => window.open('https://programadrauge.lt')} src={newdrauge}></img>
                <X onClick={() => { setModalOpen(false) }}>X</X>
            </div>
        </div>
    </Dialog>
};

export default TempDialog;


const X = styled.div`
    margin-left: auto; 
    font-size: 19px;
    transition: 0.4s;
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 8px;

	 &:hover {
        color: #4c4c4c;
        cursor: pointer;
	} 
`

const Tekstas = styled.div`
    font-size: 24px;
    text-align: center;
    padding: 18px 0;
`

const Containeris = styled.div`
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    background: #e2eade;
    align-items: center;
`

const Mygtukas = styled.div`
    font-size: 20px;
    padding: 14px 26px;
    background: #28689a;
    cursor: pointer;
    border-radius: 24px;
    color: white;
    transition: 0.4s;

    &:hover {	
        background: #20537b;
	} 
`

