import React from 'react';
import classnames from 'classnames';
import * as styles from './Button.module.scss';

export enum ButtonType {
    Primary = 'primary',
    PrimaryWhite = 'primaryWhite',
    SquareContained = 'squareContained',
    SquareOutlined = 'squareOutlined',
    Secondary = 'secondary',
    SecondaryWhite = 'secondaryWhite',
    UnActive = 'unactive',
    Bigger = 'bigger'
}

interface Props {
    title: string;
    type: ButtonType;
    loading?: boolean;
    className?: string;
    onClick?: () => void;
}

const Button = ({loading, title, type, className, onClick}: Props) => {
    return (
        <button className={classnames(className, styles.button, styles[type], loading ? styles.loading : '')} onClick={onClick}>
            <span>{loading ? 'Prašome palaukti...' : title}</span>
        </button>
    );
};

export default Button;
