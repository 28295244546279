import React from 'react';
import * as styles from './NavBar.module.scss';
import Button, { ButtonType } from '../../components/shared/Button/Button';
import defaultLogo from '../../assets/svg/logo.svg';
import socCentraiLogo from '../../assets/img/SV-soc-centrai.png';
import draugystesLogo from '../../assets/img/SV-draugystes.png';
import { Link } from 'gatsby';
import MobileMenu from './MobileMenu/MobileMenu';
import DUK from '../duk/DUK';

let logo: any;

switch (process.env.GATSBY_PUSLAPIS) {
    case 'kriziucentrai':
        logo = socCentraiLogo;
        break;
    case 'niekienovaikai':
        logo = defaultLogo;
        break;
    case 'savanoriaitevams':
        logo = draugystesLogo;
        break;
    case 'savanoriaivaikams':
        break;
        console.error("current env: ", process.env.GATSBY_PUSLAPIS, " logo nerastas");
}

const NavBar = () => (
    <>
        <div className="container d-lg-block d-none">
            <div className={styles.navBar}>
                <Link to="/">
                    <img src={logo} />
                </Link>
                <div className={styles.leftSide}>
                    <Link to="/apie-mus">
                        <button>Apie mus</button>
                    </Link>
                    <Link to="/kontaktai">
                        <button>Kontaktai</button>
                    </Link>
                    <DUK />
                </div>
                <div>
                    <Link to="/kreipkis-pagalbos">
                        <Button title="KREIPKIS PAGALBOS" type={ButtonType.Primary} />
                    </Link>

                    <Link to="/tapk-savanoriu">
                        <Button title="TAPK SAVANORIU" type={ButtonType.Primary} />
                    </Link>

                    <Link to="/paremti">
                        <Button title="PAREMK" type={ButtonType.Primary} />
                    </Link>
                </div>
            </div>
        </div>
        <MobileMenu />
    </>
);

export default NavBar;
 