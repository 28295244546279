// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-sv-12-proc-index-tsx": () => import("./../../../src/sv/12proc/index.tsx" /* webpackChunkName: "component---src-sv-12-proc-index-tsx" */),
  "component---src-sv-index-tsx": () => import("./../../../src/sv/index.tsx" /* webpackChunkName: "component---src-sv-index-tsx" */),
  "component---src-sv-mokejimas-atsauktas-index-tsx": () => import("./../../../src/sv/mokejimas-atsauktas/index.tsx" /* webpackChunkName: "component---src-sv-mokejimas-atsauktas-index-tsx" */),
  "component---src-sv-mokejimas-sekmingas-index-tsx": () => import("./../../../src/sv/mokejimas-sekmingas/index.tsx" /* webpackChunkName: "component---src-sv-mokejimas-sekmingas-index-tsx" */),
  "component---src-sv-paremk-index-tsx": () => import("./../../../src/sv/paremk/index.tsx" /* webpackChunkName: "component---src-sv-paremk-index-tsx" */),
  "component---src-sv-paremk-paramos-dezute-tsx": () => import("./../../../src/sv/paremk/ParamosDezute.tsx" /* webpackChunkName: "component---src-sv-paremk-paramos-dezute-tsx" */),
  "component---src-sv-paremk-paramos-dialogas-tsx": () => import("./../../../src/sv/paremk/ParamosDialogas.tsx" /* webpackChunkName: "component---src-sv-paremk-paramos-dialogas-tsx" */)
}

